<script>
	import { closeModal } from 'svelte-modals';
	import { afterNavigate } from '$app/navigation';

	/**
	 * @typedef {Object} Props
	 * @property {any} component
	 * @property {any} props
	 * @property {any} isOpen
	 */

	/** @type {Props} */
	let { component, props, isOpen } = $props();

	afterNavigate((navigate) => {
		closeModal();
	});
	/**
	 * @param {{ key: string; }} event
	 */
	function HandleKeypress(event) {
		if (event.key === 'Escape') {
			closeModal();
		}
	}
</script>

{#if isOpen}
	<!-- svelte-ignore a11y_no_noninteractive_tabindex -->
	<!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
	{@const SvelteComponent = component}
	<!-- svelte-ignore a11y_no_noninteractive_element_interactions -->
	<div role="dialog" class="modal" onkeydown={HandleKeypress}>
		<div class="contents">
			<SvelteComponent {props} $$restProps on:click />
		</div>
	</div>
{/if}

<style>
	.modal {
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		pointer-events: none;
		z-index: 20;
	}

	.contents {
		min-width: 240px;
		border-radius: 6px;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		pointer-events: auto;
		max-height: 100vh;
	}
</style>
